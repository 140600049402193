/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { convertImageToPDF } from '../helpers/Helpers';
import privateAxios from '../utils/privateAxios';

//======================================== CONTEXTO ========================================//
const AdminContext = createContext();
//======================================== CONTEXTO ========================================//
// Función para manejar errores de autenticación

//======================================== PROVIDER ========================================//
const AdminProvider = ({ children }) => {
    const navigate = useNavigate();

    //======================================== FUNCIONES DE AYUDA ========================================//
    /**
     * Maneja errores de autenticación y otros errores de API.
     * @param {Object} error - Objeto de error de Axios.
     */
    const handleError = (error) => {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) 
            navigate('/login');
        return {succes: false, state: false}
    };

    /**
     * Obtiene los datos del becario, permitiendo especificar parámetros adicionales en la URL
     * y parámetros de consulta.
     * @param {Object} options - Opciones para configurar la solicitud.
     * @param {string} [options.urlPath=''] - Segmento adicional de URL para la solicitud.
     * @param {Object} [options.queryParams=null] - Parámetros de consulta para la solicitud.
     * @returns {Promise<Object>} Datos del becario.
     */
    const getAdminData = async ({ urlPath = '', queryParams = null } = {}) => {
        try {
            const config = queryParams ? { params: queryParams } : {};
            const response = await privateAxios.get(`${urlPath}`, config);
            return response.data.data ?? null;
        } catch (error) {
            handleError(error);
        }
    };

    /**
     * Actualiza los datos del becario, permitiendo especificar un segmento de URL, un cuerpo de solicitud,
     * y parámetros de consulta.
     * @param {Object} options - Opciones para configurar la solicitud.
     * @param {string} [options.urlPath=''] - Segmento adicional de URL para la solicitud.
     * @param {Object} [options.body={}] - Cuerpo de la solicitud para actualizar datos del becario.
     * @param {Object} [options.queryParams=null] - Parámetros de consulta para la solicitud.
     * @returns {Promise<Object|null>} Datos del becario actualizados o null si no hay datos.
     */
    const putAdminData = async ({ urlPath = '', body = {}, queryParams = null } = {}) => {
        try {
            const config = queryParams ? { params: queryParams } : {};
            const response = await privateAxios.put(`${urlPath}`, body, config);
            return response.data.data ?? null;
        } catch (error) {
            return handleError(error);
        }
    };

    /**
     * Realiza una solicitud POST a una URL especificada con los datos proporcionados.
     * @param {string} url - URL completa para la solicitud POST.
     * @param {Object} body - Cuerpo de la solicitud para enviar.
     * @param {Object} [queryParams=null] - Parámetros de consulta opcionales.
     * @returns {Promise<Object|null>} Respuesta del servidor o null en caso de error.
     */
    const postAdminData = async ({urlPath = '', body = {}, queryParams = null}) => {
        try {
            const config = queryParams ? { params: queryParams } : {};
            const response = await privateAxios.post(`${urlPath}`, body, config);
            return response.data.data ?? null
        } catch (error) {
            handleError(error);
            return null;
        }
    };

    /**
     * Guarda un archivo PDF en el servidor.
     * @param {Object} body - Objeto con los datos del archivo (id, campo).
     * @param {File} file - El archivo PDF a subir.
     * @returns {Object} - Respuesta del servidor.
     */
    const uploadAdminPDF = async (formData, url) => {
        try {
            // Extraer el archivo desde FormData
            const file = formData.get('file');
            
            if (!file) {
                throw new Error('No se proporcionó un archivo.');
            }
    
            const isImage = ['image/jpeg', 'image/png', 'image/jpg'].includes(file.type);
            const processFile = isImage 
                ? await new Promise((resolve, reject) => {
                    convertImageToPDF(file, (error, pdfBlob) => {
                        if (error) {
                            reject(error);  // Rechazar el Promise si hay un error
                        } else {
                            resolve(pdfBlob);  // Resolver con el blob PDF
                        }
                    });
                }).catch(error => {
                    console.error('Error al convertir imagen a PDF:', error);
                    return null;
                }) 
                : file;
    
            if (!processFile) return null;
    
            // Reemplazar el archivo en FormData con el archivo procesado
            formData.set('file', processFile);
    
            // Hacer la solicitud POST con FormData
            const response = await privateAxios.post(`${url}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            
            return response.data.success;
        } catch (error) {
            handleError(error);
            return null;
        }
    };

    /**
     * Obtiene un archivo PDF del servidor.
     * @param {string} path - El endpoint en el servidor desde donde se descargará el PDF.
     * @param {Object} body - Parámetros que se enviarán en el cuerpo de la solicitud POST.
     * @returns {boolean} - Indica si la operación fue exitosa.
     */
    const getAdminPDF = async (path, body) => {
        try {
            const response = await privateAxios.post(`${path}`, body,  {
                responseType: 'blob',
            });

            const contentType = response.headers['content-type'];
            if (contentType === 'application/pdf') {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');
                return true;
            } else {
                return false;
            }
        } catch (error) {
            handleError(error)
            return null;
        }
    };

    /**
     * Descarga una plantilla de Excel desde el servidor.
     * @param {string} template - Nombre del template a descargar.
     * @returns {Promise<boolean|null>} - Devuelve true si la descarga fue exitosa, false si no es un archivo Excel, y null en caso de error.
     */
    const downloadExcelTemplate = async (template) => {
        try {
            // Solicita el archivo Excel al servidor
            const response = await privateAxios.get(`/bulk/${template}`, {
                responseType: 'blob', // Manejar la respuesta como archivo binario
            });

            const contentType = response.headers['content-type'];
            const isExcelFile = contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') 
                            || contentType.includes('application/vnd.ms-excel');

            // Validar que el archivo recibido sea de tipo Excel
            if (isExcelFile) {
                const blob = new Blob([response.data], { type: contentType });
                const url = window.URL.createObjectURL(blob);

                // Crear un enlace temporal para descargar el archivo
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${template}_template.xlsx`); // Nombre del archivo
                document.body.appendChild(link);

                // Simular el click para iniciar la descarga
                link.click();

                // Limpiar recursos
                window.URL.revokeObjectURL(url); // Liberar la URL temporal
                link.remove(); // Eliminar el enlace temporal del DOM

                return true; // Descarga exitosa
            } else {
                console.error('El archivo descargado no es un archivo Excel válido.');
                return false; // Respuesta no válida
            }
        } catch (error) {
        console.error('Error al descargar la plantilla de Excel:', error.message || error);
        return null; // Error inesperado
    }
};
    


    //======================================== FUNCIONES DEL CONTEXT ========================================//

    //---------------------------------MÉTODOS FETCH---------------------------------//
    //________________________Tablas________________________//
    /**
     * Obtiene los datos de la tabla según la consulta especificada.
     * @param {Object} query - Objeto de consulta para la solicitud.
     * @param {string} url - URL o ruta para la solicitud.
     * @returns {Object} Datos de la tabla o null si ocurre un error.
     */
    const fetchAdminTable = async (query, url, module=null) => {
        const urlPath = module ? `/${url}/Search/${module}` : `/${url}/Search`;
        const response = await postAdminData({ urlPath, body: query });

        if (response) return { rows: response.rows, count: response.count };
        else return null;
    }; 

    /**
     * Obtiene los filtros para la tabla especificada.
     * @param {string} url - URL de la tabla para la cual obtener los filtros.
     * @returns {Promise<Object>} Filtros para la tabla.
     */
    const fetchAdminFilters = async (url) => {
        return await getAdminData({ urlPath: `/${url}/filters` });
    };

    //________________________Detail________________________//
    /**
     * Obtiene los datos de la entidad especificada por su ID.
     * @param {string} url - Parte de la URL específica para la entidad.
     * @param {number} id - Identificador de la entidad a obtener.
     * @returns {Promise<Object|null>} Datos de la entidad o null en caso de error.
     */
    const fetchAdminBecarioById = async (url, id) => {
        if(id)
            return await getAdminData({ urlPath: `/${url}/${id}` });
    };

    //________________________Detail________________________//
    /**
     * Obtiene los datos de la entidad especificada por su ID.
     * @param {string} url - Parte de la URL específica para la entidad.
     * @param {number} id - Identificador de la entidad a obtener.
     * @returns {Promise<Object|null>} Datos de la entidad o null en caso de error.
     */
    const fetchAdminBecarioNotas = async (url, id) => {
        if(id)
            return await getAdminData({ urlPath: `/BecarioBeneficios/${url}/${id}` });
    };

    /**
     * Obtiene los campos del formulario para la entidad especificada.
     * @param {string} url - Identificador de la entidad para la cual obtener los campos del formulario.
     * @returns {Promise<Object>} Campos del formulario.
     */
    const fetchAdminFields = async (url) => {
        if(url)
            return  await getAdminData({ urlPath: `/form/${url}` });
    };
    
        /**
         * Envía la carta de compromiso a un becario especificado por su ID.
         * @param {string} becarioId - ID del becario.
         * @returns {Promise<Object|null>} - Respuesta del servidor.
         */
        const fetchAdminCartaBecario = async (becarioId) => {
            if(becarioId)
                return await getAdminData({
                    urlPath: `/funcionesBecario/cartaBecario/${becarioId}`
                });
        };

        /**
     * Envía la carta de compromiso a un becario especificado por su ID utilizando un método GET.
     * @param {string} becarioId - ID del becario.
     * @returns {Promise<Object|null>} - Respuesta del servidor.
     */
    const fetchAdminCartaBecarioCertificacion = async (becarioId) => {
        if(becarioId)
            return await getAdminData({
                urlPath: `/funcionesBecario/cartaBecarioCerticicacion/${becarioId}`
            });
    };
    
    /**
     * Envía las cartas de compromiso a los becarios especificados por sus cédulas.
     * @param {Object} cedulas - Objeto con las cédulas de los becarios.
     * @returns {Promise<Object|null>} - Respuesta del servidor.
     */
    const fetchAdminCartasBecariosCertificacion = async (cedulas) => {
        if(cedulas)
            return await postAdminData({
                urlPath: `/funcionesBecario/crearBecariosCerticicacion`,
                body: cedulas
            });
    };

    /**
     * Obtiene datos del servidor basado en la URL.
     * @param {string} url - La URL del endpoint.
     * @param {number} id - El ID del becario.
     * @returns {Promise<Object|null>} - Respuesta del servidor o null en caso de error.
     */
    const fetchAdminBenfReemById = async (url, id) => {
        if(id)
            return await getAdminData({ urlPath: `/BecarioBeneficios/${url}/${id}` });
    };

    //---------------------------------MÉTODOS PUT---------------------------------//
    //________________________Detail________________________//
    /**
     * Actualiza los datos de la entidad especificada.
     * @param {string} url - La parte de la URL específica para la actualización.
     * @param {Object} body - El cuerpo de datos a enviar para la actualización.
     * @returns {Promise<Object|null>} Datos actualizados o null en caso de error.
     */
    const putAdminUpdate = async (urlPath, body) => {
        return await putAdminData({ urlPath, body });
    };

    /**
     * Retira un becario especificado por su ID y la causa de retiro.
     * @param {Object} body - Objeto con los datos del retiro (becarioId y causalRetiroId).
     * @returns {Promise<Object|null>} - Respuesta del servidor.
     */
    const putAdminRetiro = async (body) => {
        return await putAdminData({ urlPath: '/funcionesBecario/retiroBecario', body });
    };

    /**
     * Actualiza el estado de una factura.
     * @param {string} id - ID del reembolso.
     * @param {string} estadoFacturaId - Nuevo estado de la factura.
     * @param {string} razonRechazo - Razón del rechazo si aplica.
     * @param {string} customValue - Otro valor personalizado que se necesite enviar.
     * @returns {Promise<Object|null>} - Respuesta del servidor o null en caso de error.
     */
    const putAdminUpdateFactura = async (id, estadoFacturaId, razonRechazo, customValue) => {
        const body = { estadoFacturaId, razonRechazo, customValue };
        return await putAdminData({
            urlPath: `/reporteLegalizacion/${id}`,
            body: body
        });
    }

    //---------------------------------MÉTODOS POST---------------------------------//
    //________________________Tablas________________________//
    /**
     * Crea una nueva entidad.
     * @param {string} url - La parte de la URL específica para la creación de la entidad.
     * @param {Object} body - El cuerpo de datos a enviar para la creación.
     * @returns {Promise<Object|null>} Datos creados o null en caso de error.
     */
    const postNew = async (url, body) => {
        return await postAdminData({
            urlPath: `/${url}`,
            body: body
        });
    }
    
    /**
     * Actualiza los datos de la entidad especificada utilizando una operación de actualización masiva.
     * @param {string} url - La parte de la URL específica para la operación masiva.
     * @param {Object} body - El cuerpo de datos a enviar para la actualización masiva.
     * @returns {Promise<Object|null>} Datos actualizados o null en caso de error.
     */
    const postBulk = async (url, body) => {
        // Utiliza postAdminData para hacer la solicitud
        return await postAdminData({ urlPath: `/bulk/${url}`, body: body });
    };

    
    /**
     * Realiza una solicitud para ejecutar tareas automáticas en el servidor.
     * @param {Object} body - Objeto con los datos necesarios para la ejecución de tareas automáticas.
     * @returns {Promise<Object|null>} - Respuesta del servidor o null en caso de error.
     */
    const postEjecucionTareasAutomaticas = async (body) => {
        return await postAdminData({
            urlPath: '/ejecucionTareasAutomaticas',
            body: body
        });
    };


    //---------------------------------MÉTODOS PDF---------------------------------//
    /**
     * Visualiza una factura archivo PDF.
     * @param {string} fileName - Nombre del archivo de factura.
     * @returns {boolean} Indica si la operación fue exitosa.
     */
    const fetchFacturaPDF = async (fileName) => {       
        return await getAdminPDF(`/reporteLegalizacion/factura`,  { fileName } );
    };

    /**
     * Guarda un archivo PDF en el servidor.
     * @param {Object} body - Objeto con los datos del archivo (id, campo).
     * @param {File} file - El archivo PDF o imagen a subir.
     * @returns {Promise<Object|null>} - Respuesta del servidor o null en caso de error.
     */
    const saveAdminAPDF = async (body, file) => {
        try {
            const formData = new FormData();
            formData.append('file', file); // Añadir el archivo tal cual, la conversión se maneja en uploadAdminPDF
            formData.append('id', body.id);
            formData.append('campo', body.campo);

            // Llamar a uploadAdminPDF para manejar la subida del archivo
            return await uploadAdminPDF(formData, '/funcionesBecario/guardarArchivoPDF');
        } catch (error) {
            console.error('Error uploading PDF:', error);
            return handleError(error); // Asumiendo que `handleError` maneja y posiblemente registra errores
        }
    };

    /**
     * Obtiene un archivo PDF del servidor.
     * @param {Object} body - Objeto con los parámetros del archivo (fileName, campo).
     * @returns {boolean} - Indica si la operación fue exitosa.
     */
    const fetchAdminPDF = async (body) => {
        return await getAdminPDF(`/funcionesBecario/obtenerArchivoPDF`, body);
    };

    // Exponer las funciones a través del contexto
    const value = {
        fetchAdminTable,
        fetchAdminFilters,
        fetchAdminBecarioNotas,
        fetchAdminFields,
        fetchAdminBecarioById,
        fetchAdminCartaBecario,
        fetchAdminCartaBecarioCertificacion,
        fetchAdminCartasBecariosCertificacion,
        fetchAdminBenfReemById,

        putAdminUpdate,
        putAdminRetiro,
        putAdminUpdateFactura,

        postBulk,
        postNew,
        postEjecucionTareasAutomaticas,

        fetchFacturaPDF,
        saveAdminAPDF,
        fetchAdminPDF,
        downloadExcelTemplate
    };

    return (
        <AdminContext.Provider value={value}>
            {children}
        </AdminContext.Provider>
    );
};

export { AdminProvider, AdminContext };